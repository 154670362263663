<template>
  <v-app>
    <div class="card card-custom">
      <div class="card-body p-0">
        <div class="wizard wizard-2" id="kt_wizard_v2" data-wizard-state="step-first" data-wizard-clickable="true">
          <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
            <div class="row">
              <div class="col-md-6 col-lg-6 col-sm-12 text-left">
                <h4>Manage courses</h4>
              </div>

              <div class="col-md-6 col-lg-6 col-sm-6 text-right mb-3">
                <button @click="openDialog()" class="btn btn-primary ml-2" v-if="checkIsAccessible('course', 'create')">

                  Add course
                </button>
              </div>
              <form @submit.prevent="getCourses" class="row mt-5 mb-5 bg-filter">
                <div class="col-md-2">
                  <v-select class="form-control" v-model="search.program_id" outlined dense item-text="title"
                    item-value="id" label="Program" :items="programs" @change="getGrades">
                  </v-select>
                </div>

                <div class="col-md-2">
                  <v-select class="form-control" outlined v-model="search.grade_id" dense item-text="title"
                    item-value="id" :items="gradesLevels" label="Grade/Semester">
                  </v-select>
                </div>
                <div class="col-md-2">
                  <v-text-field class="form-control" outlined v-model="search.title" dense label="Title">
                  </v-text-field>
                </div>
                <div class="col-md-2">
                  <v-text-field class="form-control" outlined v-model="search.subject_code" dense label="Subject code">
                  </v-text-field>
                </div>
                <div class="col-md-4 mt-5 my-auto">

                  <v-btn class="btn btn-primary text-white" depressed :loading="isBusy" @click="getCourses">Search
                  </v-btn>
                  <v-btn class="btn btn-secondary ml-2" depressed :loading="isBusy" @click="_reset">Reset</v-btn>


                </div>
              </form>
            </div>

            <div class="row">
              <div class="col-xl-3 col-md-3 col-sm-12" v-for="(course, index) of courses" :key="index">
                <b-card border-variant="dark" :sub-title="course.grade.title" style="max-width: 25rem;"
                  img-src="https://picsum.photos/400/200/?image=180" img-alt="Image">
                  <template v-slot:header>
                    <h6 class="mb-0">{{ course.title }}
                      <b-dropdown class="pull-right" size="sm" variant="link"
                        toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon" no-caret
                        right no-flip>
                        <template v-slot:button-content>
                          <slot>
                            <span>
                              <i class="flaticon-more-1"></i>
                            </span>
                          </slot>
                        </template>
                        <!--begin::Navigation-->
                        <div class="navi navi-hover ">


                          <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('course', edit)">
                            <a href="#" class="navi-link" @click="openDialog(course.id)">
                              <span class="navi-icon">
                                <i class="flaticon-edit"></i>
                              </span>
                              <span class="navi-text"> Edit</span>
                            </a>
                          </b-dropdown-text>
                          <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('course', 'delete')">
                            <a href="#" class="navi-link" @click="deleteCourse(course.id)">
                              <span class="navi-icon">
                                <i class="fas fa-trash"></i>
                              </span>
                              <span class="navi-text"> Delete</span>
                            </a>
                          </b-dropdown-text>

                        </div>
                        <!--end::Navigation-->
                      </b-dropdown>
                    </h6>

                  </template>
                  <b-card-text>
                    <b>Subject Code:</b> {{ course.subject_code }}<br>
                  </b-card-text>

                  <b-card-text>A second paragraph of text in the card.
                  </b-card-text>


                  <template v-slot:footer>
                    <a href="#" class="card-link">Card link</a>
                    <b-link href="#" class="card-link">Another link</b-link>
                  </template>

                </b-card>
              </div>
            </div>

            <div class="row">
              <div class="col-12 text-center">
                <b-pagination v-if="total > 0" @input="getCourses" v-model="page" :total-rows="total" :per-page="perPage"
                  first-number last-number></b-pagination>
                <!--  -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <create-and-update ref="course" :course_id="course_id" :programId="programId" @close_dialog="closeDialog"
        @refresh="getCourses"></create-and-update>
    </div>

  </v-app>
</template>

<script>
import CreateAndUpdate from "./CreateOrUpdate"
import CoursesService from "@/core/services/courses/CoursesService";

const courseService = new CoursesService();

import GradeLevelService from "@/core/services/gradeLevel/GradeLevelService";

const gradeLevelService = new GradeLevelService();
import ProgramService from "@/core/services/level/program/ProgramService";

const programService = new ProgramService();
export default {
  name: "Courses",
  props: ["programId"],
  components: {
    CreateAndUpdate
  },
  data() {
    return {
      search: {
        program_id: "",
        grade_id: "",
        title: "",
        subject_code: "",
      },
      courses: [],
      programs: [],
      gradesLevels: [],
      course_id: null,
      isBusy: false,
      page: null,
      total: null,
      perPage: null
    };
  },
  mounted() {
    this.getPrograms();
  },
  methods: {
    getPrograms() {
      this.$bus.emit('toggleLoader',);
      programService.all().then(response => {
        this.programs = response.data;
      }).catch((err) => {
        // console.log(err)
      }).finally(() => {
        this.$bus.emit('toggleLoader');
      });
    },
    getGrades() {
      gradeLevelService.getByProgram(this.search.program_id).then(response => {
        this.gradesLevels = response.data;
      });
    },
    getAllCourses() {
      this.$bus.emit('toggleLoader',);
      courseService.all(this.search).then(response => {
        this.courses = response.data.data;
        this.$bus.emit('toggleLoader');
      });
    },
    getCourses() {
      this.isBusy = true;
      this.$bus.emit('toggleLoader',);
      courseService.paginate(this.search).then(response => {
        this.courses = response.data.data;
        this.isBusy = false;
        this.$bus.emit('toggleLoader');
      });
    },
    openDialog(id) {
      this.course_id = id;
      this.$refs["course"].showModal(id);
    },
    closeDialog(id) {
      this.course_id = id;
      this.$refs["course"].hideModal();
      this.getFaculties();
      this.course_id = null;
    },
    _reset() {
      this.search = {
        program_id: "",
        grade_id: "",
      }
      this.getPrograms();
    },
    deleteCourse(id) {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        callback: confirm => {
          if (confirm) {
            courseService.delete(id).then(response => {
              this.$snotify.success("Information deleted");
              this.getCourses();
            });
          }
        }
      });
    },
  }
};
</script>
